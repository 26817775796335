export default {
  version: {
    default: "v2",
    value: "version",
    type: "text",
  },
  correo_informes_comercial: {
    default: "@",
    value: "comercialSendReportsMail",
    type: "text",
  },
  c_status_to_upload: {
    default: "",
    value: "reportsRemarks",
    type: "text",
  },
  c_participants_number: {
    default: 0,
    value: "assemblyRealParticipants",
    type: "number",
  },
  c_cantidad_participantes: {
    default: 0,
    value: "assemblyValuedParticipants",
    type: "number",
  },
  c_is_inWallet: {
    default: "No",
    value: "isAssemblyClientInWallet",
    type: "text",
  },
  c_fecha_envio: {
    default: null,
    value: "reportsDateSend",
    type: "date",
    format: "DD-MM-YYYY",
  },
  c_eleccion_servicio: {
    default: "",
    value: "assemblyServiceType",
    type: "text",
  },
  c_nombre_ph_empresa: {
    default: "",
    value: "assemblyClientOrganizationName",
    type: "text",
  },
  c_cot_status: {
    default: "",
    value: "assemblyStatus",
    type: "text",
  },
  c_correo_cotizante: {
    default: "@",
    value: "assemblyServiceClientMail",
    type: "text",
  },
  c_advance_value: {
    default: 0,
    value: "assemblyServiceAdvance",
    type: "number",
  },
  c_revisado_por: {
    default: "",
    value: "reportsReviewedBy",
    type: "text",
  },
  c_telefono_porteria: {
    default: "",
    value: "assemblyClientPorterTel",
    type: "text",
  },
  c_advance_status: {
    default: "",
    value: "assemblyClientAdvanceStatus",
    type: "text",
  },
  id: {
    default: "",
    value: "assemblyId",
    type: "text",
  },
  c_id_cot: {
    default: "",
    value: "assemblyCotNumber",
    type: "text",
  },
  c_direccion_lugar_del_servicio: {
    default: "",
    value: "assemblyServiceAddress",
    type: "text",
  },
  c_fecha_segunda_convocatoria: {
    default: null,
    value: "assemblyServiceSecondAnnoucementDate",
    type: "date",
    format: "DD-MM-YYYY hh:mm a",
  },
  c_service_tags: {
    default: [],
    value: "assemblyServiceTags",
    type: "array",
  },
  c_requiere_envio_informes: {
    default: "",
    value: "reportsSendWoPayment",
    type: "text",
  },
  c_zoom_user: {
    default: "@",
    value: "assemblyRoomMeetingUser",
    type: "text",
  },
  c_nombre_admin: {
    default: "",
    value: "assemblyClientAdminName",
    type: "text",
  },
  c_total_final: {
    default: 0,
    value: "assemblyServiceTotalPrice",
    type: "number",
  },
  c_correo_envia_factura: {
    default: "@",
    value: "invoicesMailShouldSend",
    type: "text",
  },
  c_observaciones: {
    default: "",
    value: "assemblyRemarks",
    type: "text",
  },
  c_hora_finalizacion_asamblea: {
    default: null,
    value: "assemblyServiceDateFinished",
    type: "date",
    format: "DD-MM-YYYY hh:mm a",
  },
  c_correo_envia_informes: {
    default: "@",
    value: "assemblyServiceReportsMail",
    type: "text",
  },
  dateCreated: {
    default: null,
    value: "assemblyDateCreated",
    type: "date",
    format: "YYYY-MM-DD HH:mm:ss.S",
  },
  c_enviado_por: {
    default: "",
    value: "reportsSentBy",
    type: "text",
  },
  c_telefono_admin: {
    default: "",
    value: "assemblyClientAdminTel",
    type: "text",
  },
  c_fecha_primera_convocatoria: {
    default: null,
    value: "assemblyServiceFirstAnnoucementDate",
    type: "date",
    format: "DD-MM-YYYY hh:mm a",
  },
  c_meeting_url: {
    default: "sistevot",
    value: "assemblyRoomMeetingUrl",
    type: "text",
  },
  c_c_status_logistica: {
    default: "bg-white",
    value: "assemblyLogisticaStatus",
    type: "text",
  },
  c_c_status_comercial: {
    default: "bg-white",
    value: "assemblyComercialStatus",
    type: "text",
  },
  c_c_status_contabilidad: {
    default: "bg-white",
    value: "assemblyContabilidadStatus",
    type: "text",
  },
  c_c_status_talento: {
    default: "bg-white",
    value: "assemblyTalentoStatus",
    type: "text",
  },
  c_agendado_po: {
    default: "",
    value: "assemblyApprovedBy",
    type: "text",
  },
  c_acta_qnty: {
    default: 0,
    value: "assemblyServiceRecordQnty",
    type: "number",
  },
  c_conductor_qnty: {
    default: 0,
    value: "assemblyServiceDriverQnty",
    type: "number",
  },
  c_virtual_qnty: {
    default: 0,
    value: "assemblyServiceVirtualQnty",
    type: "number",
  },
  c_conexion_qnty: {
    default: 0,
    value: "assemblyServiceConnectionQnty",
    type: "number",
  },
  c_sonido_qnty: {
    default: 0,
    value: "assemblyServiceSoundQnty",
    type: "number",
  },
  c_registro_qnty: {
    default: 0,
    value: "assemblyServiceRegisterQnty",
    type: "number",
  },
};
