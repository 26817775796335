<template>
  <div class="relative">
    <Card
      :pt="{
        root: { class: 'bg-gray-100' },
        title: { class: 'text-gray-800 ml-2 text-xl uppercase font-bold' },
      }"
    >
      <template #title>
        <i class="pi pi-th-large"></i>
        Saldo servicios
        <hr class="bg-gray-400 mt-2 h-0.5" />
      </template>
      <template #content>
        <!-- <div class="flex justify-center h-full"> -->
        <BarChart
          :chartData="chartData"
          :chartOptionsConfig="chartOptionsConfig"
        />
        <!-- </div> -->
      </template>
    </Card>

    <Loader :isVisible="isLoading" :fullRelative="true" />
  </div>
</template>
<script setup>
const props = defineProps({
  chartOptionsConfig: {
    type: Object,
    default: {},
  },
});

import { onMounted, ref, toRaw, watch } from "vue";
import BarChart from "../chart/BarChart.vue";
import Loader from "../Loader.vue";

import { useGlobalStore } from "../../stores/global";
import { useInvoicesStore } from "../../stores/invoices";
import { craftInvoices } from "../../workshop/invoices";

const invoicesStore = useInvoicesStore();
const globalStore = useGlobalStore();
import { getInvoices } from "../../services/invoices";
import {
  filterInvoicesWithAssembly,
  getPaymentStatus,
} from "../../alchemy/invoices";

const { selectedYear } = globalStore;

const isLoading = ref(false);
const chartData = ref({});
let dataset = ref({});

const getChartDatalabels = () => {
  let dataValues = [];
  let dataLabels = [];

  for (const i in dataset.value) {
    dataValues.push(dataset.value[i]);
  }

  dataLabels = Object.keys(dataset.value);

  return { dataLabels, dataValues };
};

const setChartData = () => {
  const { dataLabels, dataValues } = getChartDatalabels();

  chartData.value = {
    labels: dataLabels,
    datasets: [
      {
        label: "Saldo servicios",
        data: dataValues,
        backgroundColor: [
          "rgb(255, 202, 128)",
          "rgb(255, 202, 128)",
          "rgb(255, 202, 128)",
        ],
        borderColor: [
          "rgb(255, 153, 0)",
          "rgb(255, 153, 0)",
          "rgb(255, 153, 0)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

onMounted(async () => {
  if (!invoicesStore.hasBeenLoaded()) {
    isLoading.value = true;
    try {
      const invoicesResource = await getInvoices(1000);

      // Extra step because of lack of backend
      const craftedInvoices = craftInvoices(invoicesResource.data);

      const alchemyInvoices = filterInvoicesWithAssembly(craftedInvoices);
      invoicesStore.setInvoices(alchemyInvoices);
    } catch (e) {
    } finally {
      isLoading.value = false;
    }
  }

  dataset.value = getPaymentStatus(toRaw(invoicesStore.invoices));

  setChartData();
});

watch(
  () => selectedYear,
  () => setChartData()
);
</script>
