import dashboardYearData from "../data/real/dashboardYearData.json";

// Expects normal js objects, not refs
export const calculateDataDashboard = (items, totalItems) => {
  const initialValue = {
    totalInvoiced: 0,
    totalAdvances: 0,
    mostPopularService: "",
    totalLastYear: 0,
    growthPercentageInvoiced: 0,
    growthPercentageAdvances: 0,
    paymentStatus: {},
    reportsStatus: {},
    totalItems: 0,
  };

  if (items.length === 0) return initialValue;

  const year = items[0].assemblyServiceFirstAnnoucementDate.year().toString();

  const { totalInvoicedApproved, totalInvoicedWaiting } = totalFacturado(
    items,
    year
  );
  const totalAdvances = totalAnticipos(items, year);
  const mostPopularService = getMostPopularService(items, year);
  const { totalAdvancesLastYear, totalLastYear } = totalFacturadoByMonth(
    items,
    year
  );

  const growthPercentageInvoiced = parseFloat(
    (((totalInvoicedApproved - totalLastYear) / totalLastYear) * 100).toFixed(2)
  );

  const growthPercentageAdvances = parseFloat(
    (((totalAdvances - totalLastYear) / totalLastYear) * 100).toFixed(2)
  );

  return {
    totalInvoicedApproved: {
      total: totalInvoicedApproved,
      growth: growthPercentageInvoiced,
    },
    totalInvoicedWaiting: {
      total: totalInvoicedWaiting,
      growth: null,
    },
    totalAdvances: {
      total: totalAdvances,
      growth: growthPercentageAdvances,
    },
    mostPopularService: {
      total: mostPopularService,
      growth: null,
    },
  };
};

const totalFacturadoByMonth = (items, year) => {
  if (
    dashboardYearData[year] !== undefined &&
    dashboardYearData[Number(year - 1)] !== undefined
  )
    return {
      totalLastYear: dashboardYearData[Number(year - 1)].totalInvoiced,
      totalAdvancesLastYear: dashboardYearData[Number(year - 1)].totalAdvances,
    };

  let totalLastYear = 0;
  let totalAdvancesLastYear = 0;
  let months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const monthsDashboard =
    dashboardYearData[new Date().getFullYear() - 1].months;

  for (const month in monthsDashboard) {
    if (monthsDashboard.hasOwnProperty(month)) {
      const details = monthsDashboard[month];
      if (months.indexOf(details.month) <= new Date().getMonth()) {
        totalLastYear += details.totalInvoiced;
        totalAdvancesLastYear += details.totalAdvances;
      }
    }
  }

  return {
    totalLastYear: parseFloat(totalLastYear.toFixed(2)),
    totalAdvancesLastYear: parseFloat(totalAdvancesLastYear.toFixed(2)),
  };
};

const totalFacturado = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].totalInvoiced;

  let totalApproved = 0;
  let totalWaiting = 0;
  items
    .filter((item) => item.assemblyStatus === "approved")
    .forEach((item) => {
      if (item.assemblyStatus === "approved")
        totalApproved += item.assemblyServiceTotalPrice || 0;
      if (item.assemblyStatus === "waiting")
        totalWaiting += item.assemblyServiceTotalPrice || 0;
    });

  return {
    totalInvoicedApproved: parseFloat(totalApproved.toFixed(2)),
    totalInvoicedWaiting: parseFloat(totalWaiting.toFixed(2)),
  };
};

const totalAnticipos = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].totalAdvances;

  let total = 0;
  items.forEach((item) => {
    total += item.assemblyServiceAdvance;
  });
  return parseFloat(total.toFixed(2));
};

const getMostPopularService = (items, year) => {
  if (dashboardYearData[year] !== undefined)
    return dashboardYearData[year].mostPopularService.service;

  let services = [];
  items.forEach((item) => {
    item.version === "v2"
      ? item.assemblyServiceTags.forEach((service) => {
          if (service) services.push(service.value);
        })
      : services.push(item.assemblyServiceType.value);
  });
  const counts = {};
  services.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  let max = Object.keys(counts).reduce((a, b) =>
    counts[a] > counts[b] ? a : b
  );

  return max;
};

export function getReportsStatus(items) {
  let status = [];
  items.forEach((item) => {
    status.push(
      item.reportsRemarks
        .toLowerCase()
        .trim()
        .replace("( ", "(")
        .replace(" )", ")")
    );
  });
  const counts = {};
  status.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  delete counts[""];

  if (counts.lenght < 10) {
    for (const key in counts) {
      if (counts.hasOwnProperty(key)) {
        const element = counts[key];
        if (element < 10) {
          counts["Otros"] = (counts["Otros"] || 0) + counts[key];
          delete counts[key];
        }
      }
    }
  }

  return counts;
}
