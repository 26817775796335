import fields from "../enums/field";
import modalCategories from "../enums/modalCategory";
import datatableTypes from "../enums/datatableTypes";
import gridItemsTypes from "../enums/gridItemTypes";

const dataTableColumns = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    dataTableType: datatableTypes.TEXT,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
    frozen: true,
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    dataTableType: datatableTypes.TEXT,
    frozen: true,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyServiceAddress,
    header: "Dirección",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    dataTableType: datatableTypes.TEXT,
    frozen: false,
  },
];

const generalSection = [
  {
    field: fields.assemblyCotNumber,
    header: "COT",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.LINK,
    link: "https://joget.clasealpha.com:8443/jw/web/userview/acomercial2022/graficos/_/cot_final_crud?_mode=edit&id=",
  },
  {
    field: fields.assemblyClientOrganizationName,
    header: "Cliente",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceFirstAnnoucementDate,
    header: "Fecha 1ra convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceSecondAnnoucementDate,
    header: "Fecha 2da convocatoria",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyServiceAddress,
    header: "Dirección",
    category: modalCategories.GENERAL_SECTION__GRID,
    modalType: gridItemsTypes.TEXT,
  },
  {
    field: fields.assemblyValuedParticipants,
    header: "Usuarios cotizados",
    category: modalCategories.GENERAL_SECTION__WIDGETLIST,
    modalType: gridItemsTypes.TEXT,
  },
];

const reportsSection = [];

const roleSection = [];

export default {
  dataTableColumns,
  generalSection,
  reportsSection,
  roleSection,
};
