<template>
  <div class="flex flex-col">
    <div class="text-base font-semibold text-gray-800">
      Hora finalización asamblea
    </div>
    <DatePicker
      v-model="assemblyServiceDateFinished"
      id="assembly-service-date-finished"
      @hide="onUpdateServiceDateFinished"
      dateFormat="DD, d 'de' M 'del' yy"
      showIcon
      showTime
      hourFormat="12"
      fluid
      class="p-0 shadow-none"
    >
    </DatePicker>
    <div class="grid grid-cols-2 pt-4 px-4">
      <div>
        <div class="text-base font-semibold text-gray-800">
          Duración asamblea
        </div>
        <InputText
          v-model="serviceExtraHours"
          id="service-extra-hours"
          :disabled="true"
        />
      </div>
      <div>
        <div class="text-base font-semibold text-gray-800">
          Cantidad operarios
        </div>
        <div v-for="(manager, index) in assemblyData.managersQnty">
          {{ manager.type }} : {{ manager.quantity }}
        </div>
      </div>
    </div>
  </div>
  <div class="flex gap-2 w-full items-center justify-between pt-4">
    <div class="text-xl text-gray-600 font-semibold">Operarios agendados</div>
    <Button @click="openManagerAdmnNewTab" :label="getLabelManagerAdm()" />
  </div>

  <ManagerTable :managers="managersBooked"></ManagerTable>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import { DatePicker } from "primevue";
import { useDayjs } from "../../composables/useDayjs";
import { updateAssemblyService } from "../../services/assembly";
import ManagerTable from "../manager/managerSection/managerTable.vue";
import { getManagersBookedByAssemblyNumber } from "../../services/manager";
import { craftManagers } from "../../workshop/manager";

const props = defineProps({
  assemblyData: {
    type: Object,
    default: {},
    required: true,
  },
  permissions: {
    type: Object,
    default: {},
    required: true,
  },
});

const emit = defineEmits(["loadSection"]);

const dayjs = useDayjs();

const managersBooked = ref([]);

const getLabelManagerAdm = () => {
  return managersBooked.value.length > 0
    ? "Administrar agenda operarios"
    : "Agendar operarios";
};

const serviceExtraHours = ref(
  props.assemblyData?.serviceExtraHours ?? "0 horas y 0 minutos"
);

const assemblyServiceDateFinished = ref(null);

const openManagerAdmnNewTab = () => {
  const linkAgendarOperarios =
    import.meta.env.VITE_JOGET_BASE_URL +
    import.meta.env.VITE_LIST_AGREGAR_OPERARIOS +
    props.assemblyData?.assemblyCotNumber;

  const linkAdministrarOperarios =
    import.meta.env.VITE_JOGET_BASE_URL +
    import.meta.env.VITE_LIST_ADM_OPERARIOS +
    props.assemblyData?.assemblyCotNumber;

  window.open(
    managersBooked.value.length > 0
      ? linkAdministrarOperarios
      : linkAgendarOperarios,
    "_blank"
  );
};

const onUpdateServiceDateFinished = async () => {
  serviceExtraHours.value = mapHorasExtras();

  const requestData = {
    id: props.assemblyData?.assemblyCotNumber,
    hora_finalizacion_asamblea: dayjs(assemblyServiceDateFinished.value).format(
      "DD-MM-YYYY hh:mm a"
    ),
  };

  try {
    emit("loadSection", true);

    const request = await updateAssemblyService(requestData);

    console.log(request.data);
  } catch (error) {
    console.error(error);
  } finally {
    emit("loadSection", false);
  }
};

const mapHorasExtras = () => {
  let totalMinutes = dayjs(assemblyServiceDateFinished.value).diff(
    dayjs(props.assemblyData?.assemblyServiceFirstAnnoucementDate),
    "minutes"
  );
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  return `${hours} horas y ${minutes} minutos`;
};

onMounted(async () => {
  assemblyServiceDateFinished.value = dayjs(
    props.assemblyData?.assemblyServiceDateFinished
  ).isValid()
    ? props.assemblyData?.assemblyServiceDateFinished.toDate()
    : null;

  try {
    emit("loadSection", true);

    const managersBookedData = await getManagersBookedByAssemblyNumber({
      pageSize: 50,
      cot: props.assemblyData?.assemblyCotNumber,
    });

    // Extra step because of lack of backend
    const craftedManagersBooked = craftManagers(managersBookedData.data);

    managersBooked.value = craftedManagersBooked;
  } catch (error) {
    console.error(error);
  } finally {
    emit("loadSection", false);
  }
});
</script>
